export const getPeriod = (periodString) => {
    let periodo
    periodString = periodString.toUpperCase()
    switch(periodString) {
        case 'SEMANAL':
        case 'SEMANAS':
        case 'S':
            periodo = 'Weekly'
            break
        case 'QUINCENAL':
        case 'QUINCENAS':
        case 'Q':
            periodo = 'Biweekly'
            break
        case 'MENSUAL':
        case 'MENSUALIDADES':
        case 'M':
            periodo = 'Monthly'
            break
        case 'CATORCENAS':
        case 'CATORCENAL':
            periodo = 'Semimonthly'
            break
        default:
            periodo = 'ERROR'
    }

    return periodo
}

export const getPeriodText = (periodString) => {
    let texto
        periodString = periodString.toUpperCase()
        switch(periodString) {
            case 'Q':
            case 'BIWEEKLY':
                texto = 'QUINCENAS'
                break
            case 'M':
            case 'MONTHLY':
                texto = 'MENSUALIDADES'
                break
            case 'S':
            case 'WEEKLY':
                texto = 'SEMANAS'
                break
            case 'SEMIMONTHLY':
                texto = 'CATORCENAL'
                break
            default:
                texto = 'ERROR'
        }
    return texto
}