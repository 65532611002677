import React from 'react'
import useProductNavigate from '../../hooks/useProductNavigate'

export default function withProductNavigate(Component) {

    return (props) => {
        const productNavigate = useProductNavigate()
        
        return (
            <Component productNavigate={productNavigate} {...props} />
        )
    }
}
