import { graphql, useStaticQuery, navigate as navigateGatsby } from 'gatsby'

export default function useProductNavigate() {
    const data = useStaticQuery(graphql`
    {
        allComponentsJson(filter: {component: {eq: "simulador-journey"}}) {
            edges {
                node {
                    id
                    routes {
                        company
                        product
                        journey
                        next {
                            path
                        }
                    }
                }
            }
        }
    }
    `)

    return ({ nextPath, company, product, journey, fallbackUrl, navigate }) => {
        const route = data.allComponentsJson.edges[0].node.routes.find(element => element.company === company && element.product === product && element.journey === journey)
        if(navigate) {
            navigate(nextPath || route?.next?.path || fallbackUrl)
        } else {
            navigateGatsby(nextPath || route?.next?.path || fallbackUrl)
        }
    }
}
