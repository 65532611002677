import Service from '../Service'
import { LandingV4Instance } from './Instance'

 /**
 * @typedef {Object} SimulatorData
 * @property {String} creditApplicationId
 * @property {String} productId
 * @property {Number} requestedAmount
 * @property {Number} terms
 * @property {String} period
 */

/**
 * @class
 * @param  {String} creditApplicationId
 */
export class SimulatorService extends Service {
  constructor(creditApplicationId) {
    super()
    this._creditApplicationId = `${creditApplicationId}`
    this._productId = ''
    this._requestedAmount = 0
    this._terms = 0
    this._period = ''
  }
  
  /**
   * @param  {String} productId
   */
  setProductId(productId) {
    this._productId = `${productId}`
  }
  
  /**
   * @param  {Number} requestedAmount
   */
  setRequestedAmount(requestedAmount) {
    this._requestedAmount = requestedAmount + 0
  }
  
  /**
   * @param  {Number} terms
   */
  setTerms(terms) {
    this._terms = terms + 0
  }
  
  /**
   * @param  {String} period - Weekly | Biweekly | Monthly
   */
  setPeriod(period) {
    if (['Weekly', 'Biweekly', 'Monthly', 'Semimonthly'].includes(period)) {
      this._period = `${period}`
    } else {
      this._setError({
        type: 'code_exception',
        message: 'period expects "Weekly", "Biweekly", "Monthly", "Semimonthly"'
      })
      throw this.error
    }
  }
  
  /**
   * @returns {SimulatorData}
   */
  get data () {
    return {
      creditApplicationId: `${this._creditApplicationId}`,
      productId: `${this._productId}`,
      requestedAmount: this._requestedAmount + 0,
      terms: this._terms + 0,
      period: `${this._period}` 
    }
  }

  
  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send () {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'POST',
      url: '/simulator',
      data: self.data
    }))
  }
} 
